import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
// import { DateField } from 'react-admin';

const MainPage = () => {
    const navigate = useNavigate();

    const [id, setId] = useState('');
    const [login, setLogin] = useState('');

    const [createdAt, setCreatedAt] = useState(new Date())
    const [updatedAt, setUpdatedAt] = useState(new Date())

    const [authorized, authorize] = useState(true)

    useEffect(() => {
        const loadCurrentUser = async () => {
            try {
                let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/current`, { method: "POST" })

                if (response.ok) {
                    authorize(true)

                    let userData = await response.json()

                    setId(userData.id)
                    setLogin(userData.login)
                    setUpdatedAt(new Date(userData.createdAt))
                    setCreatedAt(new Date(userData.updatedAt))

                    console.log(createdAt)
                    console.log(updatedAt)

                } else if (response.status === 401 || response.status === 403) {
                    authorize(false)
                } else {
                    // TODO: Сделать обработку если сломалось при получении данных о пользаке.
                    console.error(`Error in loadCurrentUser: ${response.status}: ${response.statusText}`)
                }
            } catch (error) {
                // TODO: Сделать обработку если сломалось при получении данных о пользаке.
                console.error(`Error in loadCurrentUser: ${error}`)
            }

        }

        loadCurrentUser();
    }, [])

    useEffect(() => {
        if (!authorized) {
            navigate("/login")
        }
    }, [authorized])

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const seconds = ('0' + date.getSeconds()).slice(-2);
    
        return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
      };


    return (
        <div style={{ backgroundColor: "var(--cabin-main)", height: "100vh" }}>
            <div style={{ padding: "30px", margin: "auto", display: "flex" }}>
                <cabin-icon class="emblem-white-icon" style={{ margin: "auto" }}></cabin-icon>
            </div>

            <form>
                <div style={{ margin: "auto", borderRadius: "20px", width: "500px", height: "fit-content", backgroundColor: "white" }}>
                    <div style={{ display: 'flex', flexDirection: "column", padding: "30px", fontSize: "20px", fontWeight: "bold" }}>
                        <p>EMAIL</p>
                        <input type="email" value={login} disabled />

                        <p>ID</p>
                        <input type="string" value={id} disabled />

                        <p>CREATED AT</p>
                        <input type="string" value={formatDate(createdAt)} disabled />

                        <p>UPDATED AT</p>
                        <input type="string" value={formatDate(updatedAt)} disabled />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default MainPage

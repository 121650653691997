import '../icons/icons.css'
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";


const LoginPage = () => {
    const navigate = useNavigate();
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ login, password }),
        });

        console.log(`Response: OK: ${response.ok}`)

        if (response.ok) {
            let responseData = await response.json()
            sessionStorage.setItem("userId", responseData.id)
            setIsLoggedIn(true);
        } else {
            //TODO: УДАЛИТЬ как будет реализована модалка
            alert("Ошибка авторизации")
        }
    };

    useEffect(() => {
        const checkToken = async () => {
            try {
                let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/protected`, {
                    method: "POST"
                })

                if (response.ok) {
                    setIsLoggedIn(true);
                }
            } catch (error) {
                setIsLoggedIn(false);
            }
        }

        checkToken();
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/');
        }
    }, [isLoggedIn]);

    return (
        <div style={{ backgroundColor: "var(--cabin-main)", height: "100vh" }}>
            <div style={{ padding: "30px", margin: "auto", display: "flex" }}>
                <cabin-icon class="emblem-white-icon" style={{ margin: "auto" }}></cabin-icon>
            </div>

            <form onSubmit={handleSubmit}>
                <div style={{ margin: "auto", borderRadius: "20px", width: "500px", height: "fit-content", backgroundColor: "white" }}>
                    <div style={{ display: 'flex', flexDirection: "column", padding: "30px", fontSize: "20px", fontWeight: "bold" }}>
                        <p>EMAIL</p>
                        <input type="email" placeholder="Input email" value={login} onChange={(e) => setLogin(e.target.value)} />

                        <p>PASSWORD</p>
                        <input type="password" placeholder="Input password" style={{ marginBottom: "20px" }} value={password} onChange={(e) => setPassword(e.target.value)} />

                        <div style={{ display: "flex" }}>
                            <input type="checkbox" style={{ width: "20px" }}></input>
                            <p style={{ margin: "auto 0 auto 5px" }}>REMEBER ME</p>
                        </div>

                        <div style={{ display: "flex" }}>
                            <button className='button-main'>LOGIN</button>
                            <a href='/reset_password' target='_blank' style={{ margin: "auto 10px auto auto" }}>FORGOT PASSWORD</a>
                        </div>

                        <div>
                            <p style={{ margin: "10px 0 0 0" }}>NO ACCOUNT?</p>
                            <a href='/register' target='_blank' style={{ margin: "auto 10px auto auto" }}>REGISTER</a>
                        </div>

                    </div>
                </div>
            </form>

        </div>
    )
}

export default LoginPage

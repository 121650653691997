const RegisterPage = () => {
    return (
        <div style={{ backgroundColor: "var(--cabin-main)", height: "100vh" }}>
            <div style={{ padding: "30px", margin: "auto", display: "flex" }}>
                <cabin-icon class="emblem-white-icon" style={{ margin: "auto" }}></cabin-icon>
            </div>
        </div>
    )
}

export default RegisterPage
